import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { requestPoints } from "../helpers/requestPoints.js";
import Agenda from "./Agenda";
import VideoModal from "./VideoModal";
import NetworkingModal from "./NetworkingModal";
import HybridModal from "./HybridModal";
import styles from "./Holding.module.scss";

export default class HoldingMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTickProfile: true,
      showTickAgenda: false,
      showTickVideo: false,
      showTickNetworking: false,
      showTickHybrid: false,
      showTickOnDemand: false,
      showAttendeeWall: false,
      showVideoBooth: false,
      showAgenda: false,
      showVideo: false,
      showNetworking: false,
      showHybrid: false,
      showOnDemand: false,
    };
  }


  componentDidMount () {
    // Call function to check cookies and show ticks
    // Need to have process to keep doing this after each click and new section unlocked
    this.checkForCompletedTaskCookies();
  }

  checkForCompletedTaskCookies = () => {
    const { cookies } = this.props;
    const profileCookie = cookies.get("completedProfile");
    const agendaCookie = cookies.get("completedAgenda");
    const videoCookie = cookies.get("completedVideo");
    const networkingCookie = cookies.get("completedNetworking");
    const hybridCookie = cookies.get("completedHybrid");
    const simulationCookie = cookies.get("completedSimulation");
    const onDemandCookie = cookies.get("completedOnDemand");
    if (profileCookie != undefined) {
      this.setState({ showTickProfile: true })
    }
    if (agendaCookie != undefined) {
      this.setState({ showTickAgenda: true })
    }
    if (videoCookie != undefined) {
      this.setState({ showTickVideo: true })
    }
    if (networkingCookie != undefined) {
      this.setState({ showTickNetworking: true })
    }
    if (hybridCookie != undefined) {
      this.setState({ showTickHybrid: true })
    }
    if (simulationCookie != undefined) {
      this.setState({ showTickSimulation: true })
    }
    if (onDemandCookie != undefined) {
      this.setState({ showTickOnDemand: true })
    }
  }

  showProfile = () => {
    requestPoints("holdingPageButton", "completeProfile");
    this.setState({ showTickProfile: true })
    this.props.cookies.set("completedProfile", "yes", { path: "/", maxAge: 3000000 });
    this.props.setShowProfileModal(true)
  }

  showAgenda = () => {
    requestPoints("holdingPageButton", "completeAgenda");
    this.setState({ showTickAgenda: true, showAgenda: true })
    this.props.cookies.set("completedAgenda", "yes", { path: "/", maxAge: 3000000 });
  }

  showVideo = () => {
    requestPoints("holdingPageButton", "completeVideo");
    this.setState({ showTickVideo: true, showVideo: true })
    this.props.cookies.set("completedVideo", "yes", { path: "/", maxAge: 3000000 });
  }

  showNetworking = () => {
    requestPoints("holdingPageButton", "completeHost");
    this.setState({ showTickNetworking: true, showNetworking: true })
    this.props.cookies.set("completedNetworking", "yes", { path: "/", maxAge: 3000000 });
  }

  showHybrid = () => {
    requestPoints("holdingPageButton", "completeHybrid");
    this.setState({ showTickHybrid: true, showHybrid: true })
    this.props.cookies.set("completedHybrid", "yes", { path: "/", maxAge: 3000000 });
  }

  showOnDemand = () => {
    requestPoints("holdingPageButton", "completeOnDemand");
    this.setState({ showTickOnDemand: true })
    this.props.cookies.set("completedOnDemand", "yes", { path: "/", maxAge: 3000000 });
    window.location.href = "/onDemandPre";
  }


  render () {

    return (
      <div className={styles.mobileListView} >
        <div className={styles.mobileTitleArea}>
          <h1 className={styles.customMobileTitleText}>Welcome to the Global Big Picture Event 2024</h1>
        </div>
        <strong><h4 className={styles.landingPageMobileTextBold} >We look forward to having you join us at our virtual event on Tuesday, 17 September, and can't wait to share all the exciting content with you on our platform.</h4></strong>
        <h4 className={styles.landingPageMobileText}>To maximize your experience and make sure you get the most out of your Big Picture experience, we recommend taking a moment before the live broadcast to go through the pre-event checklist available on this page.<br /><br />
          You will find valuable information about the live event in the agenda, a sneak peak of what’s to come in the teaser trailer, and details about networking and hybrid events under the relevant buttons.<br /><br />
          Explore the on-demand button for additional helpful resources.<br /><br />
          We look forward to seeing you at the live event soon!<br /><br />
        </h4>
        <div
          className={styles.mobileListItem}
          onClick={() => this.showProfile()}
        >
          <h1 className={styles.mobileListTitle}>Create &amp; update your profile</h1>
          <hr className={styles.horizRule} />
          <h5>5 mins</h5>
        </div>

        <div
          className={styles.mobileListItem}
          onClick={() => this.showAgenda()}
        >
          <h1 className={styles.mobileListTitle}>The agenda</h1>
          <hr className={styles.horizRule} />
          <h5>10 mins</h5>
        </div>

        <div
          className={styles.mobileListItem}
          onClick={() => this.showVideo()}
        >
          <h1 className={styles.mobileListTitle}>Teaser trailer</h1>
          <hr className={styles.horizRule} />
          <h5>5 mins</h5>
        </div>

        <div
          className={styles.mobileListItem}
          onClick={() => this.showNetworking()}
        >
          <h1 className={styles.mobileListTitle}>Networking opportunities</h1>
          <hr className={styles.horizRule} />
          <h5>5 mins</h5>
        </div>

        <div
          className={styles.mobileListItem}
          onClick={() => this.showHybrid()}
        >
          <h1 className={styles.mobileListTitle}>Hybrid opportunity</h1>
          <hr className={styles.horizRule} />
          <h5>5 mins</h5>
        </div>

        <div
          className={styles.mobileListItem}
          onClick={() => this.showOnDemand()}
        >
          <h1 className={styles.mobileListTitle}>Check out the On-demand area</h1>
          <hr className={styles.horizRule} />
          <h5>1 hour</h5>
        </div>




        {
          this.state.showAgenda ?
            <Agenda
              hideAgenda={() => this.setState({ showAgenda: false })}
              userProfile={this.props.userProfile}
            />
            : null
        }

        {
          this.state.showVideo ?
            <VideoModal
              hideVideo={() => this.setState({ showVideo: false })}
              userProfile={this.props.userProfile}
            />
            : null
        }

        {
          this.state.showNetworking ?
            <NetworkingModal
              hideNetworking={() => this.setState({ showNetworking: false })}
              showMobile={this.props.showMobile}
            />
            : null
        }

        {
          this.state.showHybrid ?
            <HybridModal
              hideHybrid={() => this.setState({ showHybrid: false })}
              userProfile={this.props.userProfile}
            />
            : null
        }


        {
          this.state.showSimulation ?
            <HybridModal
              hideHybrid={() => this.setState({ showHybrid: false })}
            />
            : null
        }

      </div >
    );
  }
}
