import React, { Component } from "react";
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import VideoPlayer from "./../modules/videoPlayer/VideoPlayer"
import { requestPoints } from "../helpers/requestPoints.js";
import SlidoEmbed from "../modules/slidoEmbed/SlidoEmbed";
import ChatFunction from "./../twilioChat/ChatFunction";
import { dateFormatterTimeOnly } from "../helpers/dateFormatter.js";
import styles from "./Help.module.scss";

export default class Help extends Component {
  constructor() {
    super();
    this.state = {
      showChat: true,
      showFAQ: false,
      showWelcomeVideo: false,
    };
  }

  componentDidUpdate (prevProps) {
    if (prevProps.showHelpModal !== this.props.showHelpModal) {
      const { cookies } = this.props;
      const lobbyVisitedCookie = cookies.get("lobbyVisited");
      if (lobbyVisitedCookie === undefined) {
        this.showWelcomeVideo();
        cookies.set("lobbyVisited", "yes", { path: "/" });
      } else {
        this.showChat();
      }
    }
  }


  helpModalOnAfterOpen = () => {
    requestPoints("other", "viewHelp");
  }

  showChat = () => {
    this.setState({
      showChat: true,
      showFAQ: false,
      showWelcomeVideo: false,
    });
  };

  showFAQ = () => {
    this.setState({
      showChat: false,
      showFAQ: true,
      showWelcomeVideo: false,
    });
  };

  showWelcomeVideo = () => {
    this.setState({
      showChat: false,
      showFAQ: false,
      showWelcomeVideo: true,
    });
  };

  render () {
    return (
      <div>
        <Modal
          onAfterOpen={this.helpModalOnAfterOpen}
          ariaHideApp={false}
          isOpen={this.props.showHelpModal}
          onRequestClose={() => this.props.setShowHelpModal(false)}
          contentLabel="Help Modal"
          overlayClassName={styles.modalOverlay}
          className={styles.modalContent}
          style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
        >
          <div className={styles.modalFlexboxColumn}>
            <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
              <h1 className={styles.modalPageTitle}>Help</h1>
              <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.props.setShowHelpModal(false)}
              >
                Close
                <img
                  className={styles.modalCloseIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
            </div>

            <div className={styles.sectionSelectionPanel}>
              {this.state.showChat ? (
                <Button
                  variant="success"
                  className={styles.sectionSelectionButton}
                  onClick={this.showChat}
                >
                  Ask a question
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={styles.sectionSelectionButton}
                  onClick={this.showChat}
                >
                  Ask a question
                </Button>
              )}
              {this.state.showFAQ ? (
                <Button
                  variant="success"
                  className={styles.sectionSelectionButton}
                  onClick={this.showFAQ}
                >
                  FAQ
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={styles.sectionSelectionButton}
                  onClick={this.showFAQ}
                >
                  FAQ
                </Button>
              )}
              {this.state.showWelcomeVideo ? (
                <Button
                  variant="success"
                  className={styles.sectionSelectionButton}
                  onClick={this.showWelcomeVideo}
                >
                  Welcome video
                </Button>
              ) : (
                <Button
                  variant="primary"
                  className={styles.sectionSelectionButton}
                  onClick={this.showWelcomeVideo}
                >
                  Welcome video
                </Button>
              )}
            </div>

            <div className={styles.scrollableArea}>
              {this.state.showChat ? (
                <div>
                  <h1>Request help</h1>
                  <p className={styles.helpMessage}>
                    If you require any help please submit your request below and
                    a member of the team will be with you as soon as possible.
                  </p>
                  <br />
                  <div className={styles.helpChatBoxArea}>
                    {/**<ChatFunction
                      userProfile={this.props.userProfile}
                      chatChannel="help-arm-big-picture-2022"
              />*/}
                    <iframe
                      title="Slido Iframe"
                      src={"https://app.sli.do/event/cVhmpjPdyYqFqozrCEfAif"}
                      height="100%"
                      width="100%"
                      style={{ minHeight: "400px" }}
                    ></iframe>
                  </div>
                  <br />
                </div>
              ) : null}
              {this.state.showFAQ ? (
                <div>
                  <h1>FAQ</h1>
                  <div className={styles.faqElements}>

                    <br />
                    <ul><li>
                      <p>
                        <strong>
                          I am having technical issues who should I contact?
                        </strong>
                        <br />
                        Click on <strong>HELP</strong> where our support team are available to help on the day between {dateFormatterTimeOnly(new Date("2024-09-17T10:00:00.000+01:00"), this.props.userProfile)} – {dateFormatterTimeOnly(new Date("2024-09-17T13:00:00.000+01:00"), this.props.userProfile)}. For any queries
                        outside of this time please contact:{" "}
                        <a href="mailto:Internal-events@arm.com">
                          Internal-events@arm.com
                        </a>
                      </p>
                    </li>
                      <li>
                        <p>
                          <strong>
                          Where can I find out what is happening?
                          </strong>
                          <br />
                          Click on <strong>AGENDA</strong> to view the sessions that will be running and for a link through to the correct space!
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            Where do I go to watch the keynote session?
                          </strong>
                          <br />
                          All keynote sessions can be viewed in the TALKS room. This
                          is accessible either through the Lobby, via the navigation
                          bar at the top of each page or you can link through from
                          the <strong>AGENDA</strong>.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            How can I find out which colleagues from my office are attending this event?
                          </strong>
                          <br />
                          Click on <strong>ATTENDEES</strong>, this is accessible either through the Deck area or via the navigation bar at the top of each page.
                          You can search by office location, name, job title or group.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            I missed one of the sessions, can I watch it On-Demand?
                          </strong>
                          <br />
                          If you missed any of the sessions, please visit the Internal Events post event page here: {" "}
                          <a href="https://armh.sharepoint.com/:u:/r/sites/InternalEvents/SitePages/The-Virtual-Big-Picture-Event.aspx?csf=1&web=1&e=2LkSJ6">
                          The Virtual Big Picture Events
                          </a>
                        </p>
                      </li>
                    </ul>

                  </div>

                  <br />
                </div>
              ) : null}
              {this.state.showWelcomeVideo ? (
                <VideoPlayer
                  resourceLocator={"mux://taWDIsc02cdMmKrXmZqm5KSBWY9qardghJAhSVa8Nq8c"}
                  location={"help-welcome-video"}
                  userProfile={this.props.userProfile}
                  autoPlay={true}
                />
              ) : null}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
