import React, { Component } from "react";
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import { requestPoints } from "../helpers/requestPoints.js";
import { determineWhetherUserIsHybrid } from "../helpers/determineWhetherUserIsHybrid.js";
import AgendaRow from "./agendaElements/AgendaRow"
import styles from "./Agenda.module.scss";

export default class Agenda extends Component {
    constructor(props) {
        super(props);
        //Set default message
        this.state = {
            showDay1: true,
            showDay2: false,
            showDay3: false,
        };
    }

    agendaModalOnAfterOpen = () => {
        requestPoints("other", "viewAgenda");
        //this.reloadData();
    }


    // Function for sorting meeting records
    compare (a, b) {
        let comparison = 0;
        let timestampA = new Date();
        let timestampB = new Date();

        // Get timestamps of disparate items to compare together
        if (a.meetingSlotID != null) {
            timestampA = new Date(a.meetingSlotID.meetingSlotTimestamp);
        } else if (a.agendaItemTimestamp != null) {
            timestampA = a.agendaItemTimestamp;
        }
        if (b.meetingSlotID != null) {
            timestampB = new Date(b.meetingSlotID.meetingSlotTimestamp);
        } else if (b.agendaItemTimestamp != null) {
            timestampB = b.agendaItemTimestamp;
        }

        if (timestampA > timestampB) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    }


    switchFromAgendaToSessionSelection = () => {
        this.props.setShowSessionBookingModal(true);
    }

    clearAllDays = () => {
        this.setState({ showDay1: false, showDay2: false });
    }

    showDay1 = () => {
        this.clearAllDays();
        this.setState({ showDay1: true });
    };

    showDay2 = () => {
        this.clearAllDays();
        this.setState({ showDay2: true });
    };

    render () {
        const enSharedAgendaContent = [
            {
                agendaItemTimestamp: new Date("2024-09-17T10:30:00.000+01:00"),
                agendaItemTitle: "Welcome",
                agendaItemLocation: "/plenary",
                agendaItemLocationText: "Talks",
                agendaItemPresenters: ["David Miller"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T10:40:00.000+01:00"),
                agendaItemTitle: "The North Star",
                agendaItemLocation: "/plenary",
                agendaItemLocationText: "Talks",
                agendaItemPresenters: ["Rene Haas"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T10:45:00.000+01:00"),
                agendaItemTitle: "One Arm",
                agendaItemLocation: "/plenary",
                agendaItemLocationText: "Talks",
                agendaItemPresenters: ["Hobson Bullman"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T10:55:00.000+01:00"),
                agendaItemTitle: "An Overview of Licensing & Sales",
                agendaItemLocation: "/plenary",
                agendaItemLocationText: "Talks",
                agendaItemPresenters: ["Rachel Rees"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T11:05:00.000+01:00"),
                agendaItemTitle: "Learning & Development at Arm",
                agendaItemLocation: "/plenary",
                agendaItemLocationText: "Talks",
                agendaItemPresenters: ["Kyle Fischer"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T11:10:00.000+01:00"),
                agendaItemTitle: "The Importance of Developing Networks",
                agendaItemLocation: "/plenary",
                agendaItemLocationText: "Talks",
                agendaItemPresenters: [],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T11:15:00.000+01:00"),
                agendaItemTitle: "In Conversation with Senior Leaders",
                agendaItemLocation: "/plenary",
                agendaItemLocationText: "Talks",
                agendaItemPresenters: ["Laura Scott", "Audrey Hayden", "Sam Ellis"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T12:00:00.000+01:00"),
                agendaItemTitle: "In-Person Networking Lunch",
                agendaItemLocation: "",
                agendaItemLocationText: "In-person Networking",
                agendaItemPresenters: ["Networking Activity"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T13:30:00.000+01:00"),
                agendaItemTitle: "Top Tips for New Joiners",
                agendaItemLocation: "/plenary",
                agendaItemLocationText: "Talks",
                agendaItemPresenters: ["Various Speakers"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T13:35:00.000+01:00"),
                agendaItemTitle: "Diversity, Equity & Inclusion at Arm",
                agendaItemLocation: "/plenary",
                agendaItemLocationText: "Talks",
                agendaItemPresenters: ["Tamika Curry Smith"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T13:50:00.000+01:00"),
                agendaItemTitle: "Global Goals, Sustainability, Team Arm & ERGs",
                agendaItemLocation: "/plenary",
                agendaItemLocationText: "Talks",
                agendaItemPresenters: ["Various Speakers"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T14:20:00.000+01:00"),
                agendaItemTitle: "Wrap Up",
                agendaItemLocation: "/networking",
                agendaItemLocationText: "Networking",
                agendaItemPresenters: ["David Miller"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T14:30:00.000+01:00"),
                agendaItemTitle: "Connect with Colleagues",
                agendaItemLocation: "",
                agendaItemLocationText: "",
                agendaItemPresenters: ["Networking Activity"],
            },
            {
                agendaItemTimestamp: new Date("2024-09-17T15:00:00.000+01:00"),
                agendaItemTitle: "End of Day",
                agendaItemLocation: "",
                agendaItemLocationText: "",
                agendaItemPresenters: [],
            }
        ];

        const enVirtualOnlyAgendaContent = [
        ];

        const enHybridOnlyAgendaContent = [
        ];

        var enAgendaContent = [];
        if (determineWhetherUserIsHybrid(this.props.userProfile)) {
            enAgendaContent = enSharedAgendaContent.concat(enHybridOnlyAgendaContent);
        } else {
            enAgendaContent = enSharedAgendaContent.concat(enHybridOnlyAgendaContent);
        }

        // Meeting to only have fixed items (no meetings)
        const agendaContentWithMeetings = enAgendaContent;
        // Sort combined array
        const agendaContentWithMeetingsSorted = agendaContentWithMeetings.sort(this.compare);

        // Get user timezone offset to work out start and end points of their calendar days
        var userTimezoneOffset = 0;
        if (this.props.userProfile !== null) {
            userTimezoneOffset = this.props.userProfile.userTimezone.offset;
        }

        // Work out start and end time points for local user's days
        var day1StartTimestamp = new Date("2022-12-07T00:00:00.000+00:00");
        day1StartTimestamp.setHours(day1StartTimestamp.getHours() - userTimezoneOffset);

        const day1EndTimestamp = new Date("2026-12-07T23:59:59.000+00:00");
        day1EndTimestamp.setHours(day1EndTimestamp.getHours() - userTimezoneOffset);

        const agendaContentWithMeetingsSortedAndFilteredDay1 = agendaContentWithMeetingsSorted.filter((agendaItem) =>
            (agendaItem.agendaItemTimestamp && agendaItem.agendaItemTimestamp > day1StartTimestamp && agendaItem.agendaItemTimestamp < day1EndTimestamp))

        var day2StartTimestamp = new Date("2022-12-08T00:00:00.000+00:00");
        day2StartTimestamp.setHours(day2StartTimestamp.getHours() - userTimezoneOffset);

        const day2EndTimestamp = new Date("2022-12-08T23:59:59.000+00:00");
        day2EndTimestamp.setHours(day2EndTimestamp.getHours() - userTimezoneOffset);

        const agendaContentWithMeetingsSortedAndFilteredDay2 = agendaContentWithMeetingsSorted.filter((agendaItem) =>
            (agendaItem.agendaItemTimestamp && agendaItem.agendaItemTimestamp > day2StartTimestamp && agendaItem.agendaItemTimestamp < day2EndTimestamp))


        const agendaContentDay1 = agendaContentWithMeetingsSortedAndFilteredDay1.map((agendaItem, index) => {
            return (
                <AgendaRow
                    key={index}
                    agendaItemDetails={agendaItem}
                    userProfile={this.props.userProfile}
                    setShowAgendaModal={this.props.setShowAgendaModal}
                    setShowLeaderboardModal={this.props.setShowLeaderboardModal}
                    switchFromAgendaToSessionSelection={this.switchFromAgendaToSessionSelection}
                />
            )
        }
        );

        const agendaContentDay2 = agendaContentWithMeetingsSortedAndFilteredDay2.map((agendaItem, index) => {
            return (
                <AgendaRow
                    key={index}
                    agendaItemDetails={agendaItem}
                    userProfile={this.props.userProfile}
                    setShowAgendaModal={this.props.setShowAgendaModal}
                    setShowLeaderboardModal={this.props.setShowLeaderboardModal}
                    switchFromAgendaToSessionSelection={this.switchFromAgendaToSessionSelection}
                />
            )
        }
        );

        return (
            <div>
                <Modal
                    onAfterOpen={this.agendaModalOnAfterOpen}
                    isOpen={true}
                    ariaHideApp={false}
                    onRequestClose={() => this.props.hideAgenda()}
                    contentLabel="Agenda Modal"
                    overlayClassName={styles.modalOverlay}
                    className={styles.modalContent}
                    style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
                >
                    <div className={styles.modalFlexboxColumn}>
                        <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
                            <h1 className={styles.modalPageTitle}>Agenda</h1>
                            <button
                                className={styles.modalClose}
                                variant="success"
                                onClick={() => this.props.hideAgenda()}
                                aria-label="Close"
                            >
                                Close
                                <img
                                    className={styles.modalCloseIcon}
                                    src="/icons/close-no-circle.png"
                                    alt="Close"
                                />
                            </button>
                        </div>


                        <div className={styles.daySelectionPanel}>
                            {this.state.showDay1 ? (
                                <Button
                                    variant="success"
                                    className={styles.daySelectionButton}
                                    onClick={this.showDay1}
                                >
                                    Day 1
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    className={styles.daySelectionButton}
                                    onClick={this.showDay1}
                                >
                                    Day 1
                                </Button>
                            )}
                            {/**this.state.showDay2 ? (
                                <Button
                                    variant="success"
                                    className={styles.daySelectionButton}
                                    onClick={this.showDay2}
                                >
                                    Day 2
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    className={styles.daySelectionButton}
                                    onClick={this.showDay2}
                                >
                                    Day 2
                                </Button>
                            )*/}
                        </div>

                        <div className={styles.scrollableArea}>
                            {this.state.showDay1 && this.props.userProfile ?
                                <div className={styles.agendaTableContainer}>
                                    {this.props.userProfile && agendaContentDay1}
                                </div> : null}

                            {this.state.showDay2 && this.props.userProfile ?
                                <div className={styles.agendaTableContainer}>
                                    {this.props.userProfile && agendaContentDay2}
                                </div> : null}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}