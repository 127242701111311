import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { requestPoints } from "./../helpers/requestPoints.js";
import Agenda from "./Agenda";
import VideoModal from "./VideoModal";
import NetworkingModal from "./NetworkingModal";
import HybridModal from "./HybridModal";
import HoldingMobile from "./HoldingMobile";
import styles from "./Holding.module.scss";

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTickProfile: true,
      showTickAgenda: false,
      showTickVideo: false,
      showTickNetworking: false,
      showTickHybrid: false,
      showTickOnDemand: false,
      showAttendeeWall: false,
      showVideoBooth: false,
      showAgenda: false,
      showVideo: false,
      showNetworking: false,
      showHybrid: false,
      showOnDemand: false,
    };
  }


  componentDidMount () {
    // Call function to check cookies and show ticks
    // Need to have process to keep doing this after each click and new section unlocked
    this.checkForCompletedTaskCookies();
  }

  checkForCompletedTaskCookies = () => {
    const { cookies } = this.props;
    const profileCookie = cookies.get("completedProfile");
    const agendaCookie = cookies.get("completedAgenda");
    const videoCookie = cookies.get("completedVideo");
    const networkingCookie = cookies.get("completedNetworking");
    const hybridCookie = cookies.get("completedHybrid");
    const simulationCookie = cookies.get("completedSimulation");
    const onDemandCookie = cookies.get("completedOnDemand");
    if (profileCookie != undefined) {
      this.setState({ showTickProfile: true })
    }
    if (agendaCookie != undefined) {
      this.setState({ showTickAgenda: true })
    }
    if (videoCookie != undefined) {
      this.setState({ showTickVideo: true })
    }
    if (networkingCookie != undefined) {
      this.setState({ showTickNetworking: true })
    }
    if (hybridCookie != undefined) {
      this.setState({ showTickHybrid: true })
    }
    if (simulationCookie != undefined) {
      this.setState({ showTickSimulation: true })
    }
    if (onDemandCookie != undefined) {
      this.setState({ showTickOnDemand: true })
    }
  }

  showProfile = () => {
    requestPoints("holdingPageButton", "completeProfile");
    this.setState({ showTickProfile: true })
    this.props.cookies.set("completedProfile", "yes", { path: "/", maxAge: 3000000 });
    this.props.setShowProfileModal(true)
  }

  showAgenda = () => {
    requestPoints("holdingPageButton", "completeAgenda");
    this.setState({ showTickAgenda: true, showAgenda: true })
    this.props.cookies.set("completedAgenda", "yes", { path: "/", maxAge: 3000000 });
  }

  showVideo = () => {
    requestPoints("holdingPageButton", "completeVideo");
    this.setState({ showTickVideo: true, showVideo: true })
    this.props.cookies.set("completedVideo", "yes", { path: "/", maxAge: 3000000 });
  }

  showNetworking = () => {
    requestPoints("holdingPageButton", "completeHost");
    this.setState({ showTickNetworking: true, showNetworking: true })
    this.props.cookies.set("completedNetworking", "yes", { path: "/", maxAge: 3000000 });
  }

  showHybrid = () => {
    requestPoints("holdingPageButton", "completeHybrid");
    this.setState({ showTickHybrid: true, showHybrid: true })
    this.props.cookies.set("completedHybrid", "yes", { path: "/", maxAge: 3000000 });
  }

  showOnDemand = () => {
    requestPoints("holdingPageButton", "completeOnDemand");
    this.setState({ showTickOnDemand: true })
    this.props.cookies.set("completedOnDemand", "yes", { path: "/", maxAge: 3000000 });
  }

  render () {

    return (
      <>
        {this.props.showMobile ?
          <HoldingMobile
            onDemandContent={this.state.onDemandContent}
            userProfile={this.props.userProfile}
            cookies={this.props.cookies}
            setShowProfileModal={this.props.setShowProfileModal}
            showMobile={this.props.showMobile}
          />
          :
          <div className={styles.proportionalDiv}>
            <img
              className={styles.backgroundImage}
              src={window.$videoPath + "holding-sept-17.jpg"}
              alt="Background"
            ></img>

            <div
              className={styles.buttonProfile}
              onClick={() => this.showProfile()}
            />

            {this.state.showTickProfile ?
              <img
                src={window.$videoPath + "icons/tick.png"}
                className={styles.tickProfile}
              />
              : null
            }

            <div
              className={styles.buttonAgenda}
              onClick={() => this.showAgenda()}
            />

            {this.state.showTickAgenda ?
              <img
                src={window.$videoPath + "icons/tick.png"}
                className={styles.tickAgenda}
              />
              : null
            }

            <div
              className={styles.buttonVideo}
              onClick={() => this.showVideo()}
            />

            {this.state.showTickVideo ?
              <img
                src={window.$videoPath + "icons/tick.png"}
                className={styles.tickVideo}
              />
              : null
            }

            <div
              className={styles.buttonNetworking}
              onClick={() => this.showNetworking()}
            />

            {this.state.showTickNetworking ?
              <img
                src={window.$videoPath + "icons/tick.png"}
                className={styles.tickNetworking}
              />
              : null
            }

            <div
              className={styles.buttonHybrid}
              onClick={() => this.showHybrid()}
            />

            {this.state.showTickHybrid ?
              <img
                src={window.$videoPath + "icons/tick.png"}
                className={styles.tickHybrid}
              />
              : null
            }

            <Link to="/onDemandPre">
              <div
                className={styles.buttonOnDemand}
                onClick={() => this.showOnDemand()}
              />
            </Link>

            {this.state.showTickOnDemand ?
              <img
                src={window.$videoPath + "icons/tick.png"}
                className={styles.tickOnDemand}
              />
              : null
            }


            {this.state.showAgenda ?
              <Agenda
                hideAgenda={() => this.setState({ showAgenda: false })}
                userProfile={this.props.userProfile}
              />
              : null}

            {this.state.showVideo ?
              <VideoModal
                hideVideo={() => this.setState({ showVideo: false })}
                userProfile={this.props.userProfile}
              />
              : null}

            {this.state.showNetworking ?
              <NetworkingModal
                hideNetworking={() => this.setState({ showNetworking: false })}
              />
              : null}

            {this.state.showHybrid ?
              <HybridModal
                hideHybrid={() => this.setState({ showHybrid: false })}
                userProfile={this.props.userProfile}
                showMobile={this.props.showMobile}
              />
              : null}

          </div>
        }
      </>
    );
  }
}
